import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import { NavItemType } from '@/@mantis/types/menu';

import NavCollapse from './NavCollapse';
import NavItem from './NavItem';

interface Props {
  item: NavItemType;
  lastItem?: number;
  remItems: NavItemType[];
  lastItemId?: string;
  setSelectedID: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedID: string | undefined;
  setSelectedItems: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedItems: string | undefined;
  setSelectedLevel: React.Dispatch<React.SetStateAction<number>>;
  selectedLevel: number;
}

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
};

const NavGroup: React.FC<Props> = ({
  item,
  lastItem,
  remItems,
  lastItemId,
  setSelectedID,
  setSelectedItems,
  selectedItems,
  setSelectedLevel,
  selectedLevel,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { isOpen } = useDrawer();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [anchorEl, setAnchorEl] = useState<
    VirtualElement | (() => VirtualElement) | null | undefined
      >(null);
  const [currentItem, setCurrentItem] = useState(item);

  const openMini = Boolean(anchorEl);

  useEffect(() => {
    if (lastItem) {
      if (item.id === lastItemId) {
        const localItem = { ...item };
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const elements = remItems.map((ele: NavItemType) => ele.elements!);
        localItem.children = elements.flat(1);

        setCurrentItem(localItem);
      } else {
        setCurrentItem(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, lastItem, downLG]);

  const checkOpenForParent = (child: NavItemType[], id: string) => {
    child.forEach((ele: NavItemType) => {
      if (ele.children?.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        checkOpenForParent(ele.children, currentItem.id!);
      }

      if (ele.url && !!matchPath(ele?.link ?? ele.url, pathname)) {
        setSelectedID(id);
      }
    });
  };

  const checkSelectedOnload = (data: NavItemType) => {
    const childrens = data.children ? data.children : [];
    childrens.forEach((itemCheck: NavItemType) => {
      if (itemCheck?.children?.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        checkOpenForParent(itemCheck.children, currentItem.id!);
      }

      if (itemCheck.url && !!matchPath(itemCheck.link ?? itemCheck.url, pathname)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setSelectedID(currentItem.id!);
      }
    });
  };

  useEffect(() => {
    checkSelectedOnload(currentItem);
    if (openMini) setAnchorEl(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentItem]);

  const navCollapse = item.children?.map((menuItem, index) => {
    switch (menuItem.type) {
    case 'collapse':
      return (
        <NavCollapse
          key={menuItem.id}
          menu={menuItem}
          setSelectedItems={setSelectedItems}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedItems={selectedItems}
          level={1}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parentId={currentItem.id!}
        />
      );
    case 'item':
      return <NavItem key={menuItem.id} item={menuItem} level={1} />;
    default:
      return (
      // eslint-disable-next-line react/no-array-index-key
        <Typography key={index} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
        </Typography>
      );
    }
  });

  return (
    <List
      subheader={
        item.title ? (
          isOpen && (
            <Box sx={{ pl: 3, mb: 1.5 }}>
              <Typography variant="body4">{item.title}</Typography>
              {item.caption && <Typography variant="caption">{item.caption}</Typography>}
            </Box>
          )
        ) : (
          <Divider sx={{ my: 0.5 }} />
        )
      }
      sx={{ mt: isOpen && item.title ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
};

export default NavGroup;
