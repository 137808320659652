export default function OverridesInputBase() {
  return {
    MuiInputBase: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.75rem',
        },
      },
    },
  };
}
