import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TUnit } from '@/types/unit';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  buildingId: string;
  onClose: () => void;
  onUnitCreated: (unit: TUnit) => void;
}

const UnitCreateModal: React.FC<Props> = ({ onClose, onUnitCreated, buildingId }) => {
  const [name, setName] = useState('');
  const { session } = useAuth();

  const createUnitMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post<TUnit>(
        `${environment.api}/unit`,
        { name, building_id: parseInt(buildingId, 10) },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: data => {
      onUnitCreated(data);
      enqueueSnackbar(`Unit ${data.name} created successfully.`, { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to create unit.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create Unit</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          disabled={!name}
          variant="contained"
          startIcon={<PlusIcon />}
          loading={createUnitMutation.isPending}
          onClick={() => createUnitMutation.mutate()}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UnitCreateModal;
