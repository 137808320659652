import { Box, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import { Building03Icon } from '@/assets/icons/Building03Icon';

const OurMissionSection: React.FC = () => (
  <Grid2 size={12} sx={{ py: 6 }}>
    <Grid2 container spacing={4}>
      <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack spacing={3} sx={{ p: { md: 8 } }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: '50%',
              background: '#9D150033',
              border: '4px solid #9D15000D',
              outline: '4px solid #9D15000D',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Building03Icon color="primary" />
          </Box>

          <Stack spacing={2}>
            <Typography variant="h4">Our Mission</Typography>

            <Typography variant="body1">
              We're on a mission to revolutionize property management through cutting-edge AI
              technology. By automating routine tasks, providing intelligent insights, and
              streamlining communications, we help property professionals save time, reduce stress,
              and deliver better results. Our platform adapts to your unique needs, learning and
              evolving to provide increasingly personalized and effective solutions.
            </Typography>
          </Stack>
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 6 }}>
        <Box
          sx={{
            borderTopLeftRadius: { xs: 64, md: 160 },
            overflow: 'hidden',
            maxHeight: { xs: '50vw', md: 'initial' },
          }}
        >
          <img
            src="/assets/images/aboutus-mission.png"
            alt="Our Mission"
            style={{ width: '100%' }}
          />
        </Box>
      </Grid2>
    </Grid2>
  </Grid2>
);

export default OurMissionSection;
