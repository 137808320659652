import { Box, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import BlurBackground from './BlurBackground';

const team = [
  {
    photoUrl: '/assets/images/team-dipal-patel.png',
    name: 'Dipal Patel',
    title: 'Chief Executive Office',
    description:
      'With over 20 years of property management experience across diverse real estate sectors, Dipal is on a mission to revolutionize the status quo using the power of AI. By leveraging cutting-edge technology, Dipal aims to transform property management into a more efficient, transparent, and intelligent experience.',
  },
  {
    photoUrl: '/assets/images/team-nikee-patel.jpg',
    name: 'Nikee Patel',
    title: 'Director of Engineering',
    description:
      'With over 10 years of experience building winning technology products and working with AI, Nikee is leading a talented team of engineers to simplify the complexities of property management. Together, they’re reshaping the industry—making property management smarter, smoother, and more intuitive than ever.',
  },
  // {
  //   photoUrl: '/assets/images/team-carmen-negron.png',
  //   name: 'Carmen Negron',
  //   title: 'Vice President',
  //   description:
  //     'With a seasoned background in design and construction, Carmen excels in her role as VP of Design, Construction, Operations, and Property Management at Terr.ai.',
  // },
  // {
  //   photoUrl: '/assets/images/team-tricia-ursino.png',
  //   name: 'Tricia Ursino',
  //   title: 'Vice President',
  //   description:
  //     'As the Vice President of Terr.ai, Tricia utilizes her extensive experience in hospitality, sales, and administration to oversee key departments such as HR, Accounting, Legal, Development, Sales, and Finance.',
  // },
  // {
  //   photoUrl: '/assets/images/team-daniel-ferrer.png',
  //   name: 'Daniel Ferrer',
  //   title: 'Executive Assistant',
  //   description:
  //     'At Terr.ai, Daniel serves as the Executive Assistant, where he provides essential administrative support to both the CEO and senior management.',
  // },
];

const TeamSection: React.FC = () => (
  <Grid2 size={12} sx={{ py: 6 }}>
    <Grid2 container spacing={4}>
      <Grid2 size={12} sx={{ position: 'relative' }}>
        <BlurBackground
          isStrong
          sx={{
            left: '50%',
            top: 0,
            transform: 'translate(-50%, -50%)',
          }}
        />

        <Stack spacing={2.5} sx={{ textAlign: 'center', maxWidth: 770, margin: '0 auto', mb: 4 }}>
          <Stack spacing={0.75}>
            <Typography variant="overline">Meet the team</Typography>

            <Typography variant="h4">Meet our founding team</Typography>
          </Stack>

          <Typography variant="body1">
            Our philosophy is simple — hire a team of diverse, passionate people and foster a
            culture that empowers you to do your best work.
          </Typography>
        </Stack>
      </Grid2>

      <Grid2 size={{ md: 3 }} sx={{ display: { xs: 'none', md: 'block' } }} />

      {team.map(member => (
        <Grid2 key={member.name} size={{ xs: 12, sm: 6, md: 3 }}>
          <Stack spacing={3} sx={{ maxWidth: 280, margin: '0 auto' }}>
            <Box
              sx={{
                width: '100%',
                paddingTop: '105%',
                background: 'blue',
                overflow: 'hidden',
                borderTopRightRadius: 56,
                backgroundImage: `url(${member.photoUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />

            <Stack spacing={2}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle1">{member.name}</Typography>

                <Typography variant="body1" color="primary">
                  {member.title}
                </Typography>
              </Stack>

              <Typography variant="body2">{member.description}</Typography>
            </Stack>
          </Stack>
        </Grid2>
      ))}
    </Grid2>
  </Grid2>
);

export default TeamSection;
