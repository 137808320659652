import { TextField } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import React from 'react';

export const MultilineEditCell: React.FC<GridRenderEditCellParams> = props => {
  const { id, value, field, api } = props;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    api.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <TextField
      autoFocus
      size="small"
      type="text"
      color="secondary"
      fullWidth
      multiline
      value={value}
      onChange={handleOnChange}
      sx={{
        height: 'calc(100% + 2rem)',
        marginTop: '-1rem',
        marginBottom: '-1rem',
        '.MuiInputBase-root': { height: '100%' },
      }}
    />
  );
};
