import { Box, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import { Edit05Icon } from '@/assets/icons/Edit05Icon';
import { FaceHappyIcon } from '@/assets/icons/FaceHappyIcon';
import { ZapIcon } from '@/assets/icons/ZapIcon';

import BlurBackground from './BlurBackground';

const values = [
  {
    Icon: Edit05Icon,
    title: 'Innovation',
    description: 'Continuously pushing boundaries to deliver cutting-edge solutions',
  },
  {
    Icon: FaceHappyIcon,
    title: 'Reliability',
    description: 'Building trust through consistent, dependable service',
  },
  {
    Icon: ZapIcon,
    title: 'Simplicity',
    description: 'Making complex property management tasks effortlessly manageable',
  },
];

const OurValuesSection: React.FC = () => (
  <Grid2 size={12} sx={{ py: 6, pb: 12 }}>
    <Grid2 container spacing={4}>
      <Grid2 size={12} sx={{ position: 'relative' }}>
        <BlurBackground
          sx={{
            left: '50%',
            top: 0,
            transform: 'translate(-50%, -50%)',
          }}
        />

        <Stack spacing={1.5} sx={{ textAlign: 'center', maxWidth: 770, margin: '0 auto', mb: 2 }}>
          <Stack spacing={0.75}>
            <Typography variant="overline">What we stand for</Typography>

            <Typography variant="h4">Our Values</Typography>
          </Stack>

          <Typography variant="body1">
            The principles driving Terr.ai: innovative solutions, dependable performance, and
            effortless simplicity.
          </Typography>
        </Stack>
      </Grid2>

      {values.map(item => (
        <Grid2 key={item.title} size={{ xs: 12, md: 4 }}>
          <Stack
            spacing={8}
            sx={{
              py: 4,
              px: 3,
              backgroundColor: '#FAFAFA',
              height: '100%',
            }}
          >
            <Box
              sx={{
                width: 48,
                height: 48,
                borderRadius: '10px',
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <item.Icon />
            </Box>

            <Stack spacing={1}>
              <Typography variant="h5">{item.title}</Typography>

              <Typography variant="body2">{item.description}</Typography>
            </Stack>
          </Stack>
        </Grid2>
      ))}
    </Grid2>
  </Grid2>
);

export default OurValuesSection;
