export default function OverridesCardContent() {
  return {
    MuiCardContent: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          padding: 20,
          '&:last-child': {
            paddingBottom: 20,
          },
        },
      },
    },
  };
}
