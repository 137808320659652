import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { TTenant } from '@/types/tenant';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { CornerUpLeftIcon } from '@/assets/icons/CornerUpLeftIcon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  tenant: TTenant;
  onClose: () => void;
  onTenantRetrieved: (tenant: TTenant) => void;
}

const TenantRetrieveModal: React.FC<Props> = ({ tenant, onClose, onTenantRetrieved }) => {
  const { session } = useAuth();

  const retrieveTenantMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post(`${environment.api}/tenant/${tenant.id}`, undefined, {
        headers: { Authorization: `Bearer ${session?.access_token}` },
      });

      return response.data;
    },
    onSuccess: data => {
      onTenantRetrieved(data);
      enqueueSnackbar('Tenant retrieved successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to retrieve tenant.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm Retrival</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to retrieve this Tenant?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          startIcon={<CornerUpLeftIcon />}
          loading={retrieveTenantMutation.isPending}
          onClick={() => retrieveTenantMutation.mutate()}
        >
          Retrieve
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TenantRetrieveModal;
