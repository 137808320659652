import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TPropertyLocation } from '@/types/propertyLocation';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Save01Icon } from '@/assets/icons/Save01Icon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  onClose: () => void;
  onPropertyLocationCreated: (propertyLocation: TPropertyLocation) => void;
}

const PropertyLocationCreateModal: React.FC<Props> = ({ onClose, onPropertyLocationCreated }) => {
  const [name, setName] = useState('');

  const { session } = useAuth();

  const createPropertyLocationMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post<TPropertyLocation>(
        `${environment.api}/property-location`,
        { name },
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onSuccess: data => {
      onPropertyLocationCreated(data);
      enqueueSnackbar('Location created successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to create location.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create Location</DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2} mt={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          disabled={!name}
          variant="contained"
          startIcon={<Save01Icon />}
          loading={createPropertyLocationMutation.isPending}
          onClick={() => createPropertyLocationMutation.mutate()}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PropertyLocationCreateModal;
