import { Theme } from '@mui/material/styles';

export default function OverridesCard(theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          border: '1px solid',
          borderColor: theme.palette.divider,
          boxShadow: '0px 1px 2px 0px #0A0D120D',
        },
      },
    },
  };
}
