import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { HOPRoutes, PMPRoutes, PublicRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { isAssociaSubdomain } from '@/utils/communities';

interface AuthRouteState {
  from?: Location;
}

const getRedirectRoute = (isPropertyManager: boolean) => {
  if (isPropertyManager) return PMPRoutes.dashboard;
  if (isAssociaSubdomain()) return HOPRoutes.documentList;
  return PublicRoutes.chat;
};

const AuthPage: React.FC = () => {
  const { user, isPropertyManager, isPropertyManagerLoading, signInWithPassword, signUp } =
    useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  // const { signInWithOAuth } = useAuth();
  const location = useLocation<AuthRouteState>();

  const searchParams = new URLSearchParams(location.search);
  const allowSignup = searchParams.get('allowSignup') === 'true';
  const isSignup = location.pathname === PublicRoutes.signup && allowSignup;

  // const oAuth = async () => {
  //   await signInWithOAuth({
  //     provider: 'google',
  //     options: {
  //       redirectTo: window.location.href,
  //     },
  //   });
  // };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    try {
      if (isSignup) {
        const { data, error: signUpError } = await signUp({
          email,
          password,
          options: {
            emailRedirectTo: window.location.href,
          },
        });
        if (signUpError) throw signUpError;
        if (!data.session) {
          setConfirmEmail(true);
        }
      } else {
        const { error: signInError } = await signInWithPassword({ email, password });
        if (signInError) throw signInError;
      }
    } catch (err: unknown) {
      setError(err instanceof Error ? err.message : String(err));
    }
  };

  const resetAuthView = () => {
    setConfirmEmail(false);
    setEmail('');
    setPassword('');
    setAgreedToTerms(false);
  };

  if (user && location?.state?.from) return <Redirect to={location.state.from} />;

  if (user && !isPropertyManagerLoading)
    return <Redirect to={getRedirectRoute(isPropertyManager)} />;

  if (user && isPropertyManagerLoading) {
    return (
      <MuiPageWrapper>
        <Box display="flex" justifyContent="center" alignItems="center" flex="1 1 100%">
          <CircularProgress />
        </Box>
      </MuiPageWrapper>
    );
  }

  return (
    <MuiPageWrapper>
      {confirmEmail ? (
        <Container maxWidth="sm">
          <Box sx={{ mt: 8, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
              Verify your email address
            </Typography>

            <Typography variant="body1">
              To use Terr.ai, click the verification button in the email we sent to {email}. This
              helps keep your account secure.
            </Typography>

            <Typography variant="body2">
              Wrong address?{' '}
              <Link to={PublicRoutes.login} onClick={resetAuthView}>
                Try again
              </Link>{' '}
              with a different email. If you mistyped your email when signing up, create a new
              account.
            </Typography>
          </Box>
        </Container>
      ) : (
        <Container maxWidth="sm">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src="/assets/images/terrai-logo.svg"
              alt="Terr.ai Logo"
              sx={{
                width: 180,
                height: 'auto',
                mb: 4,
              }}
            />
            <Typography component="h1" variant="h4" sx={{ mb: 3 }}>
              Property Manager Portal
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />

              {isSignup && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedToTerms}
                      onChange={e => setAgreedToTerms(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      I agree to the{' '}
                      <Link to={PublicRoutes.termsOfService} target="_blank">
                        Terms and Conditions
                      </Link>
                    </Typography>
                  }
                />
              )}

              <Stack spacing={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isSignup && !agreedToTerms}
                >
                  {isSignup ? 'Sign Up' : 'Sign In'}
                </Button>

                {/* <Button fullWidth variant="outlined" onClick={oAuth} startIcon={<GoogleIcon />}>
                  {isSignup ? 'Sign Up' : 'Sign In'} with Google
                </Button> */}

                {allowSignup && (
                  <Button
                    component={Link}
                    to={isSignup ? PublicRoutes.login : `${PublicRoutes.signup}?allowSignup=true`}
                    fullWidth
                    color="inherit"
                  >
                    {isSignup
                      ? 'Already have an account? Sign In'
                      : "Don't have an account? Sign Up"}
                  </Button>
                )}
              </Stack>

              {error && (
                <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                  {error}
                </Typography>
              )}

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Link to={PublicRoutes.termsOfService} target="_blank">
                  <Typography variant="body2">Terms & Conditions</Typography>
                </Link>
                <Link to={PublicRoutes.privacyPolicy} target="_blank">
                  <Typography variant="body2">Privacy Policy</Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </MuiPageWrapper>
  );
};

export default AuthPage;
