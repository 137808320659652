import { Theme } from '@mui/material/styles';

export default function OverridesListItemSecondaryAction(theme: Theme) {
  return {
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          position: 'initial',
          transform: 'none',
        },
      },
    },
  };
}
