import { Chip } from '@mui/material';
import { useMemo } from 'react';

import { TTicket } from '@/types/ticket';

interface Props {
  ticket: TTicket;
}

const TicketSourceChip: React.FC<Props> = ({ ticket }) => {
  const label = useMemo(() => {
    if (ticket.email_id) return 'Email';

    if (ticket.sms_id) return 'SMS';
  }, [ticket]);

  if (!label) return null;

  return <Chip size="small" variant="light" color="secondary" label={label} />;
};

export default TicketSourceChip;
