import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TTenant } from '@/types/tenant';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  unitId: string;
  onClose: () => void;
  onTenantCreated: (unit: TTenant) => void;
}

const TenantCreateModal: React.FC<Props> = ({ onClose, onTenantCreated, unitId }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const { session } = useAuth();

  const createTenantMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post<TTenant>(
        `${environment.api}/tenant`,
        { unit_id: unitId, full_name: fullName, email, phone },
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onSuccess: data => {
      onTenantCreated(data);
      enqueueSnackbar(`Tenant ${data.full_name} created successfully.`, { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to create tenant.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Add Tenant</DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2} mt={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            value={fullName}
            onChange={e => setFullName(e.target.value)}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={e => setEmail(e.target.value)}
            />

            <TextField
              margin="dense"
              label="Phone"
              type="phone"
              fullWidth
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          disabled={!fullName}
          variant="contained"
          startIcon={<PlusIcon />}
          loading={createTenantMutation.isPending}
          onClick={() => createTenantMutation.mutate()}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TenantCreateModal;
