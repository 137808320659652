import { Theme } from '@mui/material/styles';

export default function OverridesListItemText(theme: Theme) {
  return {
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'body4',
          noWrap: true,
          multiline: false,
          fontWeight: 600,
          color: theme.palette.text.primary,
        },
        secondaryTypographyProps: {
          variant: 'body4',
          noWrap: true,
          multiline: false,
        },
      },
    },
  };
}
