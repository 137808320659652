import { Droppable } from '@hello-pangea/dnd';
import { Box, Typography, Card } from '@mui/material';

import { TTicket } from '@/types/ticket';

import KanbanTicket from './KanbanTicket';

interface Props {
  title: string;
  tickets: TTicket[];
  droppableId: string;
  onTicketResolved?: (ticket: TTicket) => void;
}

const KanbanColumn: React.FC<Props> = ({ title, tickets, droppableId, onTicketResolved }) => (
  <Card
    sx={{
      bgcolor: 'grey.50',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 100%',
      maxHeight: '100%',
    }}
  >
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">
        {title} ({tickets.length})
      </Typography>
    </Box>

    <Box
      sx={{
        overflowY: 'auto',
        flex: '1 0 100px',
      }}
    >
      <Droppable droppableId={droppableId} type="item">
        {(providedDrop, snapshotDrop) => (
          <Card
            ref={providedDrop.innerRef}
            {...providedDrop.droppableProps}
            sx={{
              bgcolor: snapshotDrop.isDraggingOver ? 'grey.100' : 'white',
              border: 0,

              '& > :first-child': {
                borderRadius: '12px 12px 0 0',
              },

              '& > :last-child': {
                borderRadius: '0 0 12px 12px',
              },
            }}
          >
            {tickets.map((ticket, index) => (
              <KanbanTicket
                key={ticket.id}
                ticket={ticket}
                index={index}
                onTicketResolved={onTicketResolved}
              />
            ))}

            {providedDrop.placeholder}
          </Card>
        )}
      </Droppable>
    </Box>
  </Card>
);

export default KanbanColumn;
