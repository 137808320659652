import { ListItemAvatar, ListItemText, Skeleton } from '@mui/material';

import { MessageSessionType, TMessageSession } from '@/types/message';

import Avatar from '@/@mantis/components/@extended/Avatar';
import { getPaletterColorFromString } from '@/utils/color';
import { getFromNow } from '@/utils/date';
import { formatPhoneNumber } from '@/utils/phone';
import { getSkeletonWidth } from '@/utils/skeleton';
import { getInitialsFromFullName } from '@/utils/user';

interface Props {
  messageSession: TMessageSession | null;
  isLoading?: boolean;
}

const MessageSessionListItemContent: React.FC<Props> = ({ messageSession, isLoading = false }) => {
  if (isLoading || !messageSession)
    return (
      <>
        <ListItemAvatar>
          <Skeleton
            animation={isLoading ? 'wave' : false}
            variant="circular"
            width={40}
            height={40}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Skeleton
              animation={isLoading ? 'wave' : false}
              height={24}
              width={getSkeletonWidth(50, 100)}
            />
          }
          secondary={
            <Skeleton
              animation={isLoading ? 'wave' : false}
              height={20}
              width={getSkeletonWidth(80, 120)}
            />
          }
        />
      </>
    );

  return (
    <>
      <ListItemAvatar>
        <Avatar
          color={getPaletterColorFromString(messageSession.session_id)}
          type="filled"
          sx={{ width: 40, height: 40 }}
        >
          {getInitialsFromFullName(messageSession.from.name ?? 'Unkonwn')}
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={messageSession.subject}
        secondary={`${getFromNow(new Date(messageSession.updated_at))} • ${
          messageSession.from.name
        } <${
          messageSession.type === MessageSessionType.sms
            ? formatPhoneNumber(messageSession.from.source)
            : messageSession.from.source
        }>`}
      />
    </>
  );
};

export default MessageSessionListItemContent;
