import Card, { CardProps } from '@mui/material/Card';
import CardContent, { CardContentProps } from '@mui/material/CardContent';
import CardHeader, { CardHeaderProps } from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { forwardRef, CSSProperties, ReactElement, Ref } from 'react';

import { KeyedObject } from '@/@mantis/types/root';

// header style
const headerSX = {
  p: 2.5,
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
};

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children?: ReactElement;
  subheader?: ReactElement | string;
  style?: CSSProperties;
  content?: boolean;
  contentSX?: CardContentProps['sx'];
  darkTitle?: boolean;
  divider?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  elevation?: number;
  title?: ReactElement | string;
  codeString?: string;
  modal?: boolean;
}

const MainCard: React.ForwardRefRenderFunction<HTMLDivElement, MainCardProps> = (
  {
    border = true,
    boxShadow,
    children,
    subheader,
    content = true,
    contentSX = {},
    darkTitle,
    divider = true,
    elevation,
    secondary,
    shadow,
    sx = {},
    title,
    codeString,
    modal = false,
    ...others
  }: MainCardProps,
  ref: Ref<HTMLDivElement>
) => (
  <Card
    elevation={elevation || 0}
    sx={{
      position: 'relative',
      border: border ? '1px solid' : 'none',
      borderRadius: '16px',
      borderColor: 'grey.300',
      ...(modal && {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: 'calc( 100% - 50px)', sm: 'auto' },
        '& .MuiCardContent-root': {
          overflowY: 'auto',
        },
      }),
      ...sx,
    }}
    ref={ref}
    {...others}
  >
    {/* card header and action */}
    {!darkTitle && title && (
      <CardHeader
        sx={headerSX}
        titleTypographyProps={{ variant: 'subtitle1' }}
        title={title}
        action={secondary}
        subheader={subheader}
      />
    )}
    {darkTitle && title && (
      <CardHeader
        sx={headerSX}
        title={<Typography variant="h4">{title}</Typography>}
        action={secondary}
      />
    )}

    {/* content & header divider */}
    {title && divider && <Divider />}

    {/* card content */}
    {content && <CardContent sx={contentSX}>{children}</CardContent>}
    {!content && children}

    {/* card footer - clipboard & highlighter  */}
    {codeString && <Divider sx={{ borderStyle: 'dashed' }} />}
  </Card>
);

export default forwardRef(MainCard);
