import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { MessageThreadRole, TMessageSession, TMessageThreadMessage } from '@/types/message';

import Avatar from '@/@mantis/components/@extended/Avatar';
import { useAuth } from '@/context/AuthProvider';
import { getPaletterColorFromString } from '@/utils/color';
import { formatFullDateTime } from '@/utils/date';
import { getInitials, getInitialsFromFullName } from '@/utils/user';

interface Props {
  message: TMessageThreadMessage;
  messageSession: TMessageSession;
}

const MessageThreadMessageItem: React.FC<Props> = ({ message, messageSession }) => {
  const theme = useTheme();

  const { user } = useAuth();

  if (message.role === MessageThreadRole.tenant) {
    return (
      <Stack direction="row" spacing={1.25} alignItems="flex-start">
        <Avatar color={getPaletterColorFromString(messageSession.session_id)} type="filled">
          {getInitialsFromFullName(messageSession.from.name ?? 'Unkonwn')}
        </Avatar>

        <Grid container>
          <Grid item xs={12} sm={7}>
            <Card
              sx={{
                display: 'inline-block',
                float: 'left',
                bgcolor: 'grey.200',
                boxShadow: 'none',
              }}
            >
              <CardContent sx={{ p: 1, pb: '8px !important' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body3"
                      color="text.primary"
                      sx={{ overflowWrap: 'anywhere' }}
                    >
                      {message.message}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="body4">
              {formatFullDateTime(new Date(message.updated_at))}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    );
  }

  return (
    <Stack spacing={1.25} direction="row" alignItems="flex-start" mt={0.5}>
      <Grid container justifyContent="flex-end">
        <Grid item xs={2} md={3} xl={4} />

        <Grid item xs={10} md={9} xl={8}>
          <Stack direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Card
              sx={{
                display: 'inline-block',
                float: 'right',
                bgcolor: theme.palette.primary.main,
                boxShadow: 'none',
                ml: 1,
              }}
            >
              <CardContent sx={{ p: 1, pb: '8px !important', width: 'fit-content', ml: 'auto' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body3" color="white" sx={{ overflowWrap: 'anywhere' }}>
                      {message.message}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography align="right" variant="body4">
            {formatFullDateTime(new Date(message.updated_at))}
          </Typography>
        </Grid>
      </Grid>

      {message.role === MessageThreadRole.ai && <Avatar src="/assets/images/terri-logo.svg" />}
      {message.role === MessageThreadRole.property_manager && (
        <Avatar color="primary">{getInitials(user)}</Avatar>
      )}
    </Stack>
  );
};

export default MessageThreadMessageItem;
