import { Theme } from '@mui/material/styles';

import { ExtendedStyleProps } from '@/@mantis/types/extended';
import getColors from '@/@mantis/utils/getColors';

function getColor({ color, theme }: ExtendedStyleProps & { theme: Theme }) {
  const colors = getColors(theme, color);
  const { dark } = colors;

  return {
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2,
    },
  };
}

function getColorStyle({ color, theme }: ExtendedStyleProps & { theme: Theme }) {
  const colors = getColors(theme, color);
  const { light, lighter, main } = colors;

  return {
    color: main,
    backgroundColor: lighter,
    borderColor: light,

    '& .MuiChip-deleteIcon': {
      color: main,
      '&:hover': {
        color: light,
      },
    },
  };
}

function getLightColorStyle({ color, theme }: ExtendedStyleProps & { theme: Theme }) {
  const colors = getColors(theme, color);
  const { light, main } = colors;

  return {
    color: theme.palette.text.primary,
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[0],

    '& .MuiSvgIcon-root': {
      color: main,
    },
    '& .MuiChip-deleteIcon': {
      color: main,
      '&:hover': {
        color: light,
      },
    },
  };
}

export default function OverridesChip(theme: Theme) {
  const defaultLightChip = getColorStyle({ color: 'secondary', theme });
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          border: '1px solid',
          padding: '6px 6px',
          gap: '6px',
          display: 'inline-flex',
          alignItems: 'center',
          '&:active': {
            boxShadow: 'none',
          },
          '&.MuiChip-colorPrimary': getColor({ color: 'primary', theme }),
          '&.MuiChip-colorSecondary': getColor({ color: 'secondary', theme }),
          '&.MuiChip-colorError': getColor({ color: 'error', theme }),
          '&.MuiChip-colorInfo': getColor({ color: 'info', theme }),
          '&.MuiChip-colorSuccess': getColor({ color: 'success', theme }),
          '&.MuiChip-colorWarning': getColor({ color: 'warning', theme }),

          '& .MuiChip-label': {
            padding: 0,
            display: 'inline-block',
            lineHeight: 1,
          },

          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
            margin: 0,
          },
        },
        sizeSmall: {
          fontSize: '0.75rem',
          height: 22,
        },
        sizeMedium: {
          fontSize: '1rem',
          height: 40,
        },
        light: {
          ...defaultLightChip,
          borderColor: theme.palette.grey[300],
          backgroundColor: theme.palette.grey[0],
          '&.MuiChip-lightPrimary': getLightColorStyle({ color: 'primary', theme }),
          '&.MuiChip-lightSecondary': getLightColorStyle({ color: 'secondary', theme }),
          '&.MuiChip-lightError': getLightColorStyle({ color: 'error', theme }),
          '&.MuiChip-lightInfo': getLightColorStyle({ color: 'info', theme }),
          '&.MuiChip-lightSuccess': getLightColorStyle({ color: 'success', theme }),
          '&.MuiChip-lightWarning': getLightColorStyle({ color: 'warning', theme }),
        },
        combined: {
          border: '1px solid',
          ...defaultLightChip,
          '&.MuiChip-combinedPrimary': getColorStyle({ color: 'primary', theme }),
          '&.MuiChip-combinedSecondary': getColorStyle({ color: 'secondary', theme }),
          '&.MuiChip-combinedError': getColorStyle({ color: 'error', theme }),
          '&.MuiChip-combinedInfo': getColorStyle({ color: 'info', theme }),
          '&.MuiChip-combinedSuccess': getColorStyle({ color: 'success', theme }),
          '&.MuiChip-combinedWarning': getColorStyle({ color: 'warning', theme }),
        },
      },
    },
  };
}
