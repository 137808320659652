import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState, useMemo } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import IconButton from '@/@mantis/components/@extended/IconButton';
import Transitions from '@/@mantis/components/@extended/Transitions';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import { NavItemType } from '@/@mantis/types/menu';
import { ChevronDownIcon } from '@/assets/icons/ChevronDownIcon';
import { ChevronUpIcon } from '@/assets/icons/ChevronUpIcon';

import NavItem from './NavItem';

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
};

type ListItemClick =
  | React.MouseEvent<HTMLButtonElement>
  | React.MouseEvent<HTMLAnchorElement>
  | React.MouseEvent<HTMLDivElement, MouseEvent>
  | undefined;

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '& > .MuiBox-root': {
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 25,
      ...(theme.direction !== 'rtl' && { left: -5 }),
      ...(theme.direction === 'rtl' && { right: -5 }),
      width: 10,
      height: 10,
      background: theme.palette.background.paper,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 120,
      borderLeft: '2px solid',
      borderLeftColor: theme.palette.divider,
      borderBottom: '2px solid',
      borderBottomColor: theme.palette.divider,
    },
  },
  '&[data-popper-placement="right-end"]': {
    '.MuiPaper-root': {
      marginBottom: -8,
    },
    '&:before': {
      top: 'auto',
      bottom: 5,
    },
  },
}));

interface Props {
  menu: NavItemType;
  level: number;
  parentId: string;
  setSelectedItems: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedItems: string | undefined;
  setSelectedLevel: React.Dispatch<React.SetStateAction<number>>;
  selectedLevel: number;
}

const NavCollapse: React.FC<Props> = ({
  menu,
  level,
  parentId,
  setSelectedItems,
  selectedItems,
  setSelectedLevel,
  selectedLevel,
}) => {
  const theme = useTheme();
  const { isOpen } = useDrawer();

  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null | undefined>(null);
  const [anchorEl, setAnchorEl] = useState<
    VirtualElement | (() => VirtualElement) | null | undefined
      >(null);

  const [anchorElCollapse, setAnchorElCollapse] = React.useState<null | HTMLElement>(null);

  const openCollapse = Boolean(anchorElCollapse);
  const handleClickCollapse = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorElCollapse(event.currentTarget);
  };
  const handleCloseCollapse = () => {
    setAnchorElCollapse(null);
  };

  const handleClick = (event: ListItemClick, isRedirect: boolean) => {
    setAnchorEl(null);
    setSelectedLevel(level);
    if (isOpen) {
      setOpen(!open);
      setSelected(!selected ? menu.id : null);
      setSelectedItems(!selected ? menu.id : '');
      if (menu.url && isRedirect) history.push(menu.url);
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handlerIconLink = () => {
    if (!isOpen) {
      if (menu.url) history.push(menu.url);
      setSelected(menu.id);
    }
  };

  const miniMenuOpened = Boolean(anchorEl);

  const handleClose = () => {
    setOpen(false);
    if (!miniMenuOpened) {
      if (!menu.url) {
        setSelected(null);
      }
    }
    setAnchorEl(null);
  };

  useMemo(() => {
    if (selected === selectedItems) {
      if (level === 1) {
        setOpen(true);
      }
    } else if (level === selectedLevel) {
      setOpen(false);
      if (!miniMenuOpened && !isOpen && !selected) {
        setSelected(null);
      }
      if (isOpen) {
        setSelected(null);
      }
    }
  }, [selectedItems, level, selected, miniMenuOpened, isOpen, selectedLevel]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === menu.url) {
      setSelected(menu.id);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const checkOpenForParent = (child: NavItemType[], id: string) => {
    child.forEach((item: NavItemType) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false);

    if (!miniMenuOpened) {
      setSelected(null);
    } else {
      setAnchorEl(null);
    }

    if (menu.children) {
      menu.children.forEach((item: NavItemType) => {
        if (item.children?.length) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          checkOpenForParent(item.children, menu.id!);
        }

        if (item.link && !!matchPath(item.link, pathname)) {
          setSelected(menu.id);
          setOpen(true);
        }

        if (item.url === pathname) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children]);

  useEffect(() => {
    if (menu.url === pathname) {
      setSelected(menu.id);
      setAnchorEl(null);
      setOpen(true);
    }
  }, [pathname, menu]);

  const navCollapse = menu.children?.map(item => {
    switch (item.type) {
    case 'collapse':
      return (
        <NavCollapse
          key={item.id}
          setSelectedItems={setSelectedItems}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedItems={selectedItems}
          menu={item}
          level={level + 1}
          parentId={parentId}
        />
      );
    case 'item':
      return <NavItem key={item.id} item={item} level={level + 1} />;
    default:
      return (
        <Typography key={item.id} variant="subtitle1" color="error" align="center">
            Fix - Collapse or Item
        </Typography>
      );
    }
  });

  const menuIcon = menu.icon ? <menu.icon style={{ fontSize: '1.5rem' }} /> : null;

  const isSelected = selected === menu.id;

  return (
    <>
      <ListItemButton
        id={`${menu.id}-button`}
        selected={isSelected}
        {...(!isOpen && {
          onMouseEnter: (e: ListItemClick) => handleClick(e, true),
          onMouseLeave: handleClose,
        })}
        onClick={(e: ListItemClick) => handleClick(e, true)}
        sx={{
          py: !isOpen && level === 1 ? 1.25 : 1,
          ...(isOpen && {
            pl: `${level * 28}px`,
            '&:hover': { bgcolor: theme.palette.grey[100] },
            '&.Mui-selected': {
              bgcolor: theme.palette.grey[50],
              '&:hover': {
                bgcolor: theme.palette.grey[100],
              },
            },
          }),
          ...(!isOpen && {
            pl: 1.5,
            '&:hover': { bgcolor: 'transparent' },
            '&.Mui-selected': { '&:hover': { bgcolor: 'transparent' }, bgcolor: 'transparent' },
          }),
        }}
        {...(isOpen &&
          menu.isDropdown && {
          'aria-controls': openCollapse ? `${menu.id}-menu` : undefined,
          'aria-haspopup': true,
          'aria-expanded': openCollapse ? 'true' : undefined,
          onClick: handleClickCollapse,
        })}
      >
        {menuIcon && (
          <ListItemIcon
            onClick={handlerIconLink}
            sx={{
              height: 24,
              width: 36,
              color: isSelected ? 'primary.main' : 'grey.500',
              ...(!isOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
              }),
              ...(isSelected && {
                '&:hover': {
                  bgcolor: 'primary.lighter',
                },
              }),
            }}
          >
            {menuIcon}
          </ListItemIcon>
        )}
        {(isOpen || (!isOpen && level !== 1)) && (
          <ListItemText
            primary={
              <Typography
                variant="body2"
                color={isSelected ? 'text.primary' : 'text.secondary'}
                sx={{
                  fontFamily: 'Nohemi',
                }}
              >
                {menu.title}
              </Typography>
            }
            secondary={
              <Typography variant="caption" color={isSelected ? 'text.primary' : 'text.secondary'}>
                {menu.caption}
              </Typography>
            }
            sx={{ color: 'text.primary' }}
          />
        )}

        {(isOpen || (!isOpen && level !== 1)) &&
          (menu?.url ? (
            <IconButton
              onClick={(event: ListItemClick) => {
                event?.stopPropagation();
                handleClick(event, false);
              }}
              color="secondary"
              variant="outlined"
              sx={{
                width: 20,
                height: 20,
                mr: '-5px',
                color: miniMenuOpened || open ? 'primary.main' : 'grey.500',
                borderColor: open ? 'primary.light' : 'secondary.light',
                '&:hover': { borderColor: open ? 'primary.main' : 'secondary.main' },
              }}
            >
              {miniMenuOpened || open ? (
                <ChevronUpIcon fontSize="small" />
              ) : (
                <ChevronDownIcon fontSize="small" />
              )}
            </IconButton>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {miniMenuOpened || open ? (
                <ChevronUpIcon fontSize="small" />
              ) : (
                <ChevronDownIcon fontSize="small" />
              )}
            </>
          ))}

        {!isOpen && (
          <PopperStyled
            open={miniMenuOpened}
            anchorEl={anchorEl}
            placement="right-start"
            style={{ zIndex: 2001 }}
            popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [-12, 1] } }] }}
          >
            {({ TransitionProps }) => (
              <Transitions in={miniMenuOpened} {...TransitionProps}>
                <Paper
                  sx={{
                    overflow: 'hidden',
                    mt: 1.5,
                    boxShadow: theme.customShadows.z1,
                    backgroundImage: 'none',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  {/* <ClickAwayListener onClickAway={handleClose}> */}
                  {/* eslint-disable-next-line max-len */}
                  {/* <SimpleBar sx={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '50vh' }}> */}
                  {navCollapse}
                  {/* </SimpleBar> */}
                  {/* </ClickAwayListener> */}
                </Paper>
              </Transitions>
            )}
          </PopperStyled>
        )}
      </ListItemButton>
      {isOpen && !menu?.isDropdown && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>{navCollapse}</List>
        </Collapse>
      )}
      {isOpen && menu?.isDropdown && (
        <Menu
          id={`${menu.id}-menu`}
          aria-labelledby={`${menu.id}-button`}
          anchorEl={anchorElCollapse}
          open={openCollapse}
          onClose={handleCloseCollapse}
          onClick={handleCloseCollapse}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{
            '& .MuiPaper-root': { boxShadow: theme.shadows[2] },
            '& .MuiListItemButton-root': { pl: 2 },
          }}
        >
          {navCollapse}
        </Menu>
      )}
    </>
  );
};

export default NavCollapse;
