import { Chip } from '@mui/material';

import { TTicket } from '@/types/ticket';

import { capitalizeFirstLetter } from '@/utils/text';

interface Props {
  ticket: TTicket;
}

const TicketIntentChip: React.FC<Props> = ({ ticket }) => {
  if (!ticket.intent) return null;

  return (
    <Chip
      size="small"
      variant="light"
      color="secondary"
      label={capitalizeFirstLetter(ticket.intent)}
    />
  );
};

export default TicketIntentChip;
