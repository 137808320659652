import { Chip, ChipOwnProps } from '@mui/material';
import { useMemo } from 'react';

import { TDocumentStatus } from '@/types/document';

import { AlertTriangleIcon } from '@/assets/icons/AlertTriangleIcon';
import { CheckCircleBrokenIcon } from '@/assets/icons/CheckCircleBrokenIcon';
import { ClockIcon } from '@/assets/icons/ClockIcon';
import { capitalizeFirstLetter } from '@/utils/text';

interface Props {
  status?: TDocumentStatus;
}

const DocumentStatusChip: React.FC<Props> = ({ status }) => {
  const color: ChipOwnProps['color'] = useMemo(() => {
    switch (status) {
    case 'NEW':
    case 'PROCESSING':
      return 'info';
    case 'PROCESSED':
      return 'success';
    case 'FAILED':
      return 'error';
    default:
      return 'secondary';
    }
  }, [status]);

  const icon = useMemo(() => {
    switch (status) {
    case 'NEW':
    case 'PROCESSING':
      return <ClockIcon />;
    case 'PROCESSED':
      return <CheckCircleBrokenIcon />;
    case 'FAILED':
      return <AlertTriangleIcon />;
    default:
      return undefined;
    }
  }, [status]);

  return (
    <Chip
      icon={icon}
      label={capitalizeFirstLetter(status)}
      color={color}
      variant="light"
      size="small"
    />
  );
};

export default DocumentStatusChip;
