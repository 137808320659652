import { Theme } from '@mui/material/styles';

export default function OverridesSkeleton(theme: Theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
        variant: 'text',
      },
    },
  };
}
