import { Box, Card, Link } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import { useState, useRef } from 'react';

import Avatar from '@/@mantis/components/@extended/Avatar';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import { ChevronSelectorVerticalIcon } from '@/assets/icons/ChevronSelectorVerticalIcon';
import { PublicRoutes, PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { getEmail, getFullName, getInitials } from '@/utils/user';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  drawerOpen: boolean;
}

const ExpandMore = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'expand' && prop !== 'drawerOpen',
})(({ expand, drawerOpen }: ExpandMoreProps) => {
  const theme = useTheme();
  return {
    transform: !expand ? 'rotate(0deg)' : 'rotate(-90deg)',
    marginLeft: 'auto',
    color: theme.palette.secondary.dark,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    ...(!drawerOpen && {
      opacity: 0,
      width: 50,
      height: 50,
    }),
  };
});

const NavUser: React.FC = () => {
  const { isOpen: isDrawerOpen } = useDrawer();
  const { user } = useAuth();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Card sx={{ p: 1.5 }}>
        <List disablePadding>
          <ListItem
            disablePadding
            secondaryAction={
              <ExpandMore
                ref={anchorRef}
                expand={false}
                drawerOpen={isDrawerOpen}
                onClick={() => setIsMenuOpen(true)}
                sx={{ mr: -1, mt: -1 }}
              >
                <ChevronSelectorVerticalIcon color="secondary" fontSize="small" />
              </ExpandMore>
            }
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& .MuiListItemSecondaryAction-root': {
                alignSelf: 'flex-start',
              },
            }}
          >
            <ListItemAvatar>
              <Avatar alt="Avatar" sx={{ ...(isDrawerOpen && { width: 40, height: 40 }) }}>
                {getInitials(user)}
              </Avatar>
            </ListItemAvatar>

            <ListItemText primary={getFullName(user)} secondary={getEmail(user)} />
          </ListItem>
        </List>

        {isMenuOpen && (
          <Menu
            id="nav-user-menu"
            anchorEl={anchorRef.current}
            open={isMenuOpen}
            onClose={handleClose}
            onClick={handleClose}
            MenuListProps={{ 'aria-labelledby': 'nav-user-expand-more' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <MenuItem dense component={Link} href={PublicRoutes.logout}>
              Logout
            </MenuItem>

            <MenuItem dense component={Link} href={PMPRoutes.account}>
              Account
            </MenuItem>
          </Menu>
        )}
      </Card>
    </Box>
  );
};

export default NavUser;
